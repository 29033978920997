<template>
  <div>

    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">

      <div class="card-header cursor-pointer">

        <div class="card-title m-0">

          <h3 class="fw-bolder m-0">Retrospective Component Stock Levels</h3>

        </div>

      </div>
      <div class="card-body p-7">

        <div class="row mb-3" v-if="errors == true">

          <div class="col-12">

            <div class="alert alert-danger" role="alert">
              An error occurred, please try with different date.
            </div>

          </div>

        </div>

        <div class="row mb-3">

          <div class="col-12 col-lg-12 mb-3 mb-lg-0">

            <label class="col-form-label fw-bold fs-6">Select Date</label>

            <input type="date" class="form-control" :class="{'is-invalid' : errors == true}" v-model="date">

          </div>

        </div>

        <div class="row">

          <div class="col-12">

            <button
                type="submit"
                ref="submitButton"
                class="btn btn-lg btn-primary fw-bolder ms-auto d-block my-2"
                @click="runReport"
            >
              <span class="indicator-label">
                Run Report
              </span>

              <span class="indicator-progress">
                Please wait...
                <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>

          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, onMounted, computed, ref } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/LotEnums.ts";
import { Actions as ReportActions } from "@/store/enums/ReportsEnums.ts";
import { useStore } from 'vuex';
import moment from 'moment';
export default {
  name: 'RetrospectiveComponentStockLevels',
  setup(){

    const store = useStore();

    const submitButton = ref<HTMLElement | null>(null);

    const errors = ref(false);

    const date = ref(moment(new Date()).format('YYYY-MM-DD'));

    const runReport = () => {

      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      store.dispatch(ReportActions.GET_RETROSPECTIVE_STOCK_LEVELS_REPORT,{
        date: date.value,
        type: 'component'
      })
          .then((response) => {

        errors.value = false;
        submitButton.value?.removeAttribute("data-kt-indicator");

        const blob = new Blob([response], { type: 'application/pdf', filename : 'document.pdf' } as object)

        const url = window.URL.createObjectURL(blob)

        window.open(url)

      }).catch((response) => {

        errors.value = true;

        submitButton.value?.removeAttribute("data-kt-indicator")

      })

    }

    onMounted(() => {

      setCurrentPageBreadcrumbs("Reports",[
        {title : "Retrospective Component Stock Levels",to : "/reports/retrospective-component-stock-levels"}
      ]);
    });

    return {
      store,
      date,
      runReport,
      submitButton,
      errors
    }
  }
}
</script>