
import { reactive, onMounted, computed, ref } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/LotEnums.ts";
import { Actions as ReportActions } from "@/store/enums/ReportsEnums.ts";
import { useStore } from 'vuex';
import moment from 'moment';
export default {
  name: 'RetrospectiveComponentStockLevels',
  setup(){

    const store = useStore();

    const submitButton = ref<HTMLElement | null>(null);

    const errors = ref(false);

    const date = ref(moment(new Date()).format('YYYY-MM-DD'));

    const runReport = () => {

      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      store.dispatch(ReportActions.GET_RETROSPECTIVE_STOCK_LEVELS_REPORT,{
        date: date.value,
        type: 'component'
      })
          .then((response) => {

        errors.value = false;
        submitButton.value?.removeAttribute("data-kt-indicator");

        const blob = new Blob([response], { type: 'application/pdf', filename : 'document.pdf' } as object)

        const url = window.URL.createObjectURL(blob)

        window.open(url)

      }).catch((response) => {

        errors.value = true;

        submitButton.value?.removeAttribute("data-kt-indicator")

      })

    }

    onMounted(() => {

      setCurrentPageBreadcrumbs("Reports",[
        {title : "Retrospective Component Stock Levels",to : "/reports/retrospective-component-stock-levels"}
      ]);
    });

    return {
      store,
      date,
      runReport,
      submitButton,
      errors
    }
  }
}
